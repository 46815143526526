import actionCreatorFactory from 'typescript-fsa';
import { Dispatch, Action } from 'redux';
import { API } from 'aws-amplify';

import { appActions } from './appAction';
import { InformationState, InformationItem } from '../states/informationState';

const actionCreator = actionCreatorFactory();

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

export const informationActions = {
    getInformations: actionCreator<InformationState[]>('ACTIONS_GET_INFORMATIONS'),
    getAccounts: actionCreator<InformationState[]>('ACTIONS_GET_ACCOUNTS'),
    getInformationsAsync: (accountId: string, id: string, isInit: boolean = false) => {
        return async (dispatch: Dispatch<Action>) => {
            try {
                const myInit = {
                    headers: {}
                }
                const res = await API.get(API_NAME, `/information?accountid=${accountId}&id=${id}`, myInit);
                dispatch(informationActions.getInformations(res));

                const res2 = await API.get(API_NAME, `/account`, myInit);
                dispatch(informationActions.getAccounts(res2));

            } catch (err) {
                console.log(err);
            };

            if (!isInit) {
                dispatch(appActions.setLoading(false));
            }
        };
    },
    postInformationAsync: (item: InformationItem) => {
        return async (dispatch: Dispatch<Action>) => {
            try {
                const myInit = {
                    headers: {},
                    body: item,
                };
                const url = `/information`;
                await API.post(API_NAME, url, myInit);

                // 再取得
                const myInit2 = {
                    headers: {}
                }
                const res2 = await API.get(API_NAME, `/information?accountid=${item.accountId}&id=${item.id}`, myInit2);
                dispatch(informationActions.getInformations(res2));

            } catch (err) {
                console.log(err);
            };
            dispatch(appActions.setLoading(false));
        };
    },
    deleteInformationAsync: (informationId: string, account: string, accountId: string, id: string) => {
        return async (dispatch: Dispatch<Action>) => {
            try {
                const url = `/information?informationId=${informationId}&account=${account}`;
                const myInit = {
                    headers: {}
                }
                await API.del(API_NAME, url, myInit);

                // 再取得
                const res = await API.get(API_NAME, `/information?accountid=${accountId}&id=${id}`, myInit);
                dispatch(informationActions.getInformations(res));

            } catch (err) {
                console.log(err);
            };
            dispatch(appActions.setLoading(false));
        };
    },
};