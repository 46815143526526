import actionCreatorFactory from 'typescript-fsa';
import { Dispatch, Action } from 'redux';
import { API } from 'aws-amplify';

import { WhatsnewAct } from '../states/whatsnewState';
import { appActions } from './appAction';

const actionCreator = actionCreatorFactory();

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

export const whatsnewAction = {
  getWhatsnew: actionCreator<WhatsnewAct[]>('ACTIONS_GET_WHATSNEW'),
  getWhatsnewAsync: (accountId: string, id: string, isInit: boolean = false) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {}
        }
        const res = await API.get(API_NAME, `/publication?accountid=${accountId}&id=${id}`, myInit);
        dispatch(whatsnewAction.getWhatsnew(res));

      } catch (err) {
        console.log(err);
      };

      if (!isInit) {
        dispatch(appActions.setLoading(false));
      }
    };
  },

};