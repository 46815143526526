import React from 'react';

import { useNavigate } from "react-router-dom";

import { alpha, styled } from '@mui/material/styles'

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import LocationCity from '@mui/icons-material/LocationCity';
import MailIcon from '@mui/icons-material/Mail';
import Group from '@mui/icons-material/Group';
import Logout from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Tooltip from '@mui/material/Tooltip';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Auth } from 'aws-amplify';

import { AppState } from '../states/appState';
import { AppBarActions } from '../containers/appBarContainer';
import { MainMenu } from '../components/MainMenu';

interface OwnProps { }
type AppBarProps = OwnProps & AppState & AppBarActions;

const GovName = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '24ch',
      },
    },
  },
}));

export const SampleAppBar: React.FC<AppBarProps> = (props: AppBarProps) => {
  const navigate = useNavigate();

  const [anchorElDialog, setAnchorElDialog] = React.useState<null | HTMLElement>(null);
  const openDialog = Boolean(anchorElDialog);
  const handleMenuDialog = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDialog(event.currentTarget);
  };
  const handleCloseDialog = () => {
    setAnchorElDialog(null);
  };

  const signOut = () => {
    Auth.signOut().then((data) => {
      console.log('Sign out.');
    }).catch((err) => {
      console.log(err);
      alert('サインアウトに失敗しました');
      return;
    });
  };

  let searchlInput;
  const submitSearch = (event) => {
    if (event.keyCode === 13) {
      props.setLoading(true);
      props.searchAsync({ mode: 'act', words: searchlInput.value, rowsPerPage: 10, page: 0, userGroupId: props.userGroupId, }, props.accountid, props.id);
      navigate('/search');
    }
  };

  /**
   * ウィンドウタブタイトル差し替え
   */
  const replaceTitle = () => {
    document.title = props.govName && props.govNameSuffix ? `${props.govName}${props.govNameSuffix} | LegalCRUD` : `LegalCRUD`;
    return '';
  };

  const getRoleName = () => {
    return props.role === 'Manager' ? '管理者' : props.role === 'Approver' ? '承認者' : props.role === 'Editor' ? '編集者' : '閲覧者';
  }

  return (
    <Box sx={{
      flexGrow: 1,
      height: (theme) => theme.spacing(10),
    }}>
      {replaceTitle()}
      <AppBar position="fixed" sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}>
        <Toolbar>
          <MainMenu
            accountid={props.accountid}
            role={props.role}
            id={props.id}
            idid={props.idid}
            userGroupId={props.userGroupId}
            getApprovalActs={props.getApprovalActs}
            setLoading={props.setLoading}
            getEditing={props.getEditingAsync}
            getWhatsnew={props.getWhatsnewAsync}
            searchRepealAsync={props.searchRepealAsync}
            getInformations={props.getInformationAsync}
            setOpenNewActDialog={props.setOpenNewActDialog}
            setOpenPubContentsDialog={props.setOpenPubContentsDialog}
            getPubContentsItemAsync={props.getPubContentsItemAsync}
            getUserAsync={props.getUserAsync}
          />
          <GovName variant="h6" noWrap>
            {props.govName + props.govNameSuffix}
          </GovName>
          <Search>
            <SearchIconDiv>
              <SearchIcon />
            </SearchIconDiv>
            <StyledInputBase
              placeholder="検索用語を入力してください"
              inputProps={{ 'aria-label': 'search' }}
              onKeyDown={submitSearch}
              inputRef={input => { searchlInput = input }}
            />
          </Search>
          <div>
            <Tooltip title="ユーザー情報">
              <IconButton
                sx={{ marginLeft: 2 }}
                onClick={handleMenuDialog}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Tooltip title="サインアウト">
              <IconButton
                onClick={signOut}
                color="inherit"
              >
                <Logout />
              </IconButton>
            </Tooltip>
            <Dialog
              open={openDialog}
              keepMounted
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"ユーザー情報"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="primary"
                  >
                    <LocationCity />
                  </IconButton>
                  {props.govName}<br />
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="primary"
                  >
                    <AccountCircle />
                  </IconButton>
                  {props.name}<br />
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="primary"
                  >
                    <MailIcon />
                  </IconButton>
                  {props.email}<br />
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="primary"
                  >
                    <Group />
                  </IconButton>
                  {getRoleName()}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
