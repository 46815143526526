import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { settingAction } from '../actions/settingAction';

export interface SettingState {
    usersResult: UsersResult;
    userGroups: UserGroup[];
    userGroupsState?: State;
    catalogState?: State;
    fieldState?: State;
    completeUsersAddtion: boolean;
    completeActTypeAddtion: boolean;
};

export interface State {
    state: string;
    outPath?: string;
};

export interface UserGroup {
    accountId: string;
    groupName: string;
    userGroupId: string;
    isUnlimited: boolean;
}

export interface UsersResult {
    found: number;
    start: number;
    hit: User[];
}

export interface User {
    userId: string;
    name: string;
    eMail: string;
    role: string;
    userGroupId: string;
    userGroupName: string;
    userStatus: string;
    enabled: boolean;
}

const initialState: SettingState = {
    usersResult: {
        found: 0,
        start: 0,
        hit: [],
    },
    userGroups: [],
    completeUsersAddtion: false,
    completeActTypeAddtion: false,
};

export const settingReducer = reducerWithInitialState(initialState)
    .case(settingAction.getUser, (state, payload) => {
        return Object.assign({}, state, { usersResult: payload });
    }).case(settingAction.getUserGroups, (state, payload) => {
        return Object.assign({}, state, { userGroups: payload });
    }).case(settingAction.getUserGroupsState, (state, payload) => {
        return Object.assign({}, state, { userGroupsState: payload });
    }).case(settingAction.getCatalogState, (state, payload) => {
        return Object.assign({}, state, { catalogState: payload });
    }).case(settingAction.getFieldState, (state, payload) => {
        return Object.assign({}, state, { fieldState: payload });
    }).case(settingAction.setCompleteUsersAddtion, (state, payload) => {
        return Object.assign({}, state, { completeUsersAddtion: payload });
    }).case(settingAction.setCompleteActTypeAddtion, (state, payload) => {
        return Object.assign({}, state, { completeActTypeAddtion: payload });
    });
