import actionCreatorFactory from 'typescript-fsa';
import { Dispatch, Action } from 'redux';
import { Auth, API } from 'aws-amplify';

import { informationActions } from './informationAction';
import { approvalAction } from './approvalAction';
import { editingAction } from './editingAction';
import { whatsnewAction } from './whatsnewAction';
import { repealActions } from './repealAction';
import { searchActions } from './searchAction';
import { settingAction } from './settingAction';
import { Acttemplate } from '../states/appState';

export interface Profile {
  id: string;
  name: string;
  email: string;
  accountid: string;
  govName: string;
  govNameSuffix: string;
  userGroupId: string;
  startDate: string;
  role: string;
  idid: string;
}

const actionCreator = actionCreatorFactory();

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

export const appActions = {
  getProfile: actionCreator<string>('ACTIONS_GET_PROFILE'),
  initProfile: actionCreator<Profile>('ACTION_INIT_PROFILE'),
  initEditstateList: actionCreator<any>('ACTIONS_INIT_EDITSTATELIST'),
  initEditTypeList: actionCreator<any>('ACTIONS_INIT_EDITTYPELIST'),
  getActtemplate: actionCreator<Acttemplate[]>('ACTIONS_GET_ACTTEMPLATE'),
  getActtemplateAsync: (accountid: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {}
        }
        const res = await API.get(API_NAME, `/acttemplate?accountid=${accountid}`, myInit);
        dispatch(appActions.getActtemplate(res));

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  initApplicationsAsync: (pathname: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const response = await Auth.currentUserInfo();

        // ユーザ情報取得API呼び出し
        const myInit1 = {
          headers: {}
        };
        const url1 = `/user/${response.username}`;
        const res1 = await API.get(API_NAME, url1, myInit1);

        const result: Profile = {
          id: response.username,
          name: res1.name,
          email: res1.eMail,
          accountid: res1.accountId,
          govName: res1.govName,
          govNameSuffix: res1.govNameSuffix,
          userGroupId: res1.userGroupId,
          startDate: res1.startDate,
          role: res1.role,
          idid: response.id,
        };
        dispatch(appActions.initProfile(result));

        const myInit2 = {
          headers: {}
        };
        const url2 = `/settings/${result.accountid}/editstate`;
        const res2 = await API.get(API_NAME, url2, myInit2);
        dispatch(appActions.initEditstateList(res2));

        const url3 = `/settings/${result.accountid}/edittype`;
        const res3 = await API.get(API_NAME, url3, myInit2);
        dispatch(appActions.initEditTypeList(res3));

        const url4 = `/settings/${result.accountid}/catalog`;
        const res4 = await API.get(API_NAME, url4, myInit2);
        dispatch(searchActions.initCatalogs(res4));

        const url5 = `/settings/${result.accountid}/field`;
        const res5 = await API.get(API_NAME, url5, myInit2);
        dispatch(searchActions.initFields(res5));

        await informationActions.getInformationsAsync(result.accountid, result.id, true)(dispatch);
        await editingAction.getMyEditingAsync(result.accountid, result.id, true)(dispatch);
        await whatsnewAction.getWhatsnewAsync(result.accountid, result.id, true)(dispatch);

        switch (pathname) {
          case '/approval':
            await approvalAction.getApprovalActsAsync(result.accountid, result.id)(dispatch);
            break;
          case '/editing':
            await editingAction.getEditingAsync(result.accountid, result.id)(dispatch);
            break;
          case '/repeal':
            await repealActions.searchRepealAsync({ mode: 'act', words: '', rowsPerPage: 10, page: 0, userGroupId: res1.userGroupId, }, res1.accountId)(dispatch);
            break;
          case '/settings':
            await settingAction.getUserAsync(result.accountid, result.idid, 0, 20)(dispatch);
            break;
          default:
            break;
        }
      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  setOpenNewActDialog: actionCreator<boolean>('ACTION_SET_OPEN_NEW_ACT_DIALOG'),
  setOpenPubContentsDialog: actionCreator<boolean>('ACTION_SET_OPEN_PUB_CONTENTS_DIALOG'),
  getPubContentsItem: actionCreator<boolean>('ACTION_GET_OPEN_PUB_CONTENTS_ITEM_DIALOG'),
  getPubContentsItemAsync: (accountid: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit1 = {
          headers: {}
        };
        const url1 = `/pubcontents?accountid=${accountid}`;
        const res1 = await API.get(API_NAME, url1, myInit1);

        dispatch(appActions.getPubContentsItem(res1));

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  setLoading: actionCreator<boolean>('ACTIONS_SET_LOADING'),
};
