import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory, History } from 'history';

import { appReducer, AppState } from '../states/appState';
import { currentAppReducer, CurrentActTablesState } from '../states/currentAppState';
import { editingActReducer, EditingActTablesState } from '../states/editingActsState';
import { searchReducer, SearchState } from '../states/searchState';
import { approvalReducer, ApprovalState } from '../states/approvalState';
import { editingReducer, EditingState } from '../states/editingState';
import { myEditingReducer, MyEditingState } from '../states/myEditingState';
import { whatsnewReducer, WhatsnewState } from '../states/whatsnewState';
import { repealReducer, RepealState } from '../states/repealState';
import { informationReducer, InformationState } from '../states/informationState';
import { settingReducer, SettingState } from '../states/settingState';

export interface ApplicationState {
    appState: AppState;
}

export interface CurrentActState {
    currentActTables: CurrentActTablesState;
}

export interface EditingActState {
    editingActTables: EditingActTablesState;
}

export interface EditingActsState {
    editingActsState: EditingState;
}

export interface MyEditingActsState {
    myEditingState: MyEditingState;
}

export interface SearchingState {
    searchingState: SearchState;
}

export interface ApprovalActsState {
    approvalActsState: ApprovalState;
}

export interface WhatsnewActsState {
    whatsnewActsState: WhatsnewState;
}

export interface RepealActsState {
    repealActsState: RepealState;
}

export interface InformationsState {
    informationsState: InformationState;
}

export interface SettingsState {
    settingState: SettingState;
}

export const history: History = createBrowserHistory();

const store = createStore(
    combineReducers({
        appState: appReducer,
        currentActTables: currentAppReducer,
        editingActTables: editingActReducer,
        editingActsState: editingReducer,
        myEditingState: myEditingReducer,
        searchingState: searchReducer,
        approvalActsState: approvalReducer,
        whatsnewActsState: whatsnewReducer,
        repealActsState: repealReducer,
        informationsState: informationReducer,
        settingsState: settingReducer,
    }),
    applyMiddleware(thunk)
);

store.subscribe(() =>
    console.log(store.getState())
)

export default store;
