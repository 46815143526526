import actionCreatorFactory from 'typescript-fsa';
import { Dispatch, Action } from 'redux';
import { API } from 'aws-amplify';

import { appActions } from './appAction';
import { EditingActs } from '../states/editingState';

export interface EditStateAPIParam {
  editingId: string;
  actId: string;
  editState: string;
  accountId: string;
  idid: string;
  id: string;
};

const actionCreator = actionCreatorFactory();

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

export const editingAction = {
  getEditing: actionCreator<EditingActs[]>('ACTIONS_GET_EDITING'),
  getEditingAsync: (accountId: string, id: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {}
        }
        // &state=editing 付与で編集終了前の一覧を取得
        const res = await API.get(API_NAME, `/editing?accountid=${accountId}&id=${id}&state=editing`, myInit);

        const editingActs: EditingActs[] = res.map((a,) => {
          return {
            id: a.id,
            editingActTables: (() => {
              return a.editingActs.map((item) => {
                return {
                  editingId: item.editingId,
                  actId: item.actId,
                  title: item.title,
                  newTitle: item.newTitle,
                  editType: item.editType,
                  actNum: item.actNum,
                  editActNum: item.editActNum,
                  editState: item.editState,
                  stepId: item.stepId,
                  updateDate: item.updateDate,
                  rejection: item.rejection,
                };
              });
            })(),
          };
        });
        dispatch(editingAction.getEditing(editingActs));
      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  getMyEditing: actionCreator<EditingActs[]>('ACTIONS_GET_MY_EDITING'),
  getMyEditingAsync: (accountId: string, id: string, isInit: boolean = false) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {}
        }
        // &state=myEditing 付与で自分担当編集終了前の一覧を取得
        const res = await API.get(API_NAME, `/editing?accountid=${accountId}&id=${id}&state=myEditing`, myInit);

        const editingActs: EditingActs[] = res.map((a,) => {
          return {
            id: a.id,
            editingActTables: (() => {
              return a.editingActs.map((item) => {
                return {
                  editingId: item.editingId,
                  actId: item.actId,
                  title: item.title,
                  newTitle: item.newTitle,
                  editType: item.editType,
                  actNum: item.actNum,
                  editActNum: item.editActNum,
                  editState: item.editState,
                  stepId: item.stepId,
                  updateDate: item.updateDate
                };
              });
            })(),
          };
        });
        dispatch(editingAction.getMyEditing(editingActs));
      } catch (err) {
        console.log(err);
      }

      if (!isInit) {
        dispatch(appActions.setLoading(false));
      }
    };
  },
  deleteEditingActAsync: (accountId: string, idid: string, actId: string, editingId: string, id: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const url = `/acts/${actId}/editings/${editingId}?accountid=${accountId}&idid=${idid}`;
        const myInit = {
          headers: {}
        }
        await API.del(API_NAME, url, myInit);
        // 再取得
        await editingAction.getEditingAsync(accountId, id)(dispatch);
      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    }
  },
  putEditStateAsync: (param: EditStateAPIParam) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        // 編集状態更新
        const myInit = {
          headers: {},
          body: {
            accountid: param.accountId,
            id: param.id,
            editState: param.editState,
          },
        }
        const url = `/acts/${param.actId}/editings/${param.editingId}/editstate`;
        await API.put(API_NAME, url, myInit);
        // 再取得
        await editingAction.getEditingAsync(param.accountId, param.id)(dispatch);
      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    }
  }
}
