import React from 'react';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddComment from '@mui/icons-material/AddComment';
import Comment from '@mui/icons-material/Comment';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { AppState } from '../states/appState';
import { InformationState, InformationItem } from '../states/informationState';
import { InformationActions } from '../containers/InformationContainer';
import { InformationAddDialog } from './InformationAddDialog';

type InformationProps = {} & AppState & InformationState & InformationActions;

const INITIAL_INFORMATION: InformationItem = {
  accountId: '',
  id: '',
  informationId: '',
  account: 'All',
  startDate: '',
  endDate: '',
  isUnlimited: false,
  from: '',
  type: 0,
  priority: false,
  role: 0,
  title: '',
  text: '',
  attachment: [],
  userName: '',
};

export const Information: React.FC<InformationProps> = (props) => {

  const [selectedInformation, setSelectedInformation] = React.useState<InformationItem>(INITIAL_INFORMATION);
  const updateItem = (item: InformationItem) => {
    setSelectedInformation(item);
  };

  const [subDialog, setSubDialog] = React.useState(false);
  const openSubDialog = Boolean(subDialog);
  const closeSubDialog = () => setSubDialog(false);

  const [openAddInformation, setOpenAddInformation] = React.useState(false);
  const handleAddInformationClose = () => {
    setOpenAddInformation(false);
  };
  const [modeEditInformation, setModeEditInformation] = React.useState<'ADD' | 'EDIT'>('ADD');
  const handleOkAddInformation = () => {
    props.setLoading(true);
    props.postInformation(selectedInformation);

    setOpenAddInformation(false);
    setSelectedInformation(INITIAL_INFORMATION);
  };

  const addInformation = () => {
    const additional: InformationItem = JSON.parse(JSON.stringify(INITIAL_INFORMATION));
    additional.accountId = props.accountid;
    additional.id = props.id;
    additional.account = props.role === 'Manager' ? 'All' : props.accountid;
    additional.from = props.role === 'Manager' ? props.role : props.accountid;
    setSelectedInformation(additional);
    setModeEditInformation('ADD');
    setOpenAddInformation(true);
  };

  const editInformation = (item: InformationItem) => {
    const additional: InformationItem = JSON.parse(JSON.stringify(item));
    additional.accountId = props.accountid;
    additional.id = props.id;
    setSelectedInformation(additional);
    setModeEditInformation('EDIT');
    setOpenAddInformation(true);
  };

  const deleteInformation = (item: InformationItem) => {
    const additional: InformationItem = JSON.parse(JSON.stringify(item));
    additional.accountId = props.accountid;
    additional.id = props.id;
    setSelectedInformation(additional);
    setSubDialog(true);
  };

  const deleteInformationAction = () => {
    props.setLoading(true);
    props.deleteInformation(selectedInformation.informationId, selectedInformation.account,
      selectedInformation.accountId, selectedInformation.id);
    closeSubDialog();
  };

  const createAction = (item: InformationItem) => {
    // 発信元がManagerならManagerのみに表示
    return (item.from === 'Manager' && props.role === 'Manager') || (item.from !== 'Manager' && (props.role === 'Manager' || props.role === 'Approver')) ? (
      <ListItemSecondaryAction>
        <Tooltip title="お知らせを編集する">
          <IconButton sx={{
            color: (theme) => theme.palette.secondary.main,
            margin: 1,
          }} edge="end" aria-label="edit" onClick={() => editInformation(item)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="お知らせを削除する">
          <IconButton sx={{
            color: (theme) => theme.palette.secondary.main,
            margin: 1,
          }} edge="end" aria-label="edit" onClick={() => deleteInformation(item)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    ) : '';
  };

  const createList = () => {
    const createItemIcon = (type: number) => {
      return type === 0 ? (
        <ListItemIcon sx={{
          color: (theme) => theme.palette.primary.main,
        }}>
          <InfoIcon />
        </ListItemIcon>
      ) : type === 1 ? (
        <ListItemIcon sx={{
          color: "#ffb74d",
        }}>
          <WarningIcon />
        </ListItemIcon>
      ) : (
        <ListItemIcon sx={{
          color: "#ff4500",
        }}>
          <LabelImportantIcon />
        </ListItemIcon>
      );
    };

    // textのBR
    const createText = (text: string) => {
      return text.split(/\n/).map((s, index) => (
        <span key={index}>{s}<br /></span>
      ));
    };

    const createPeriod = (e: string) => {
      return e ? '～' + e + 'まで' : '';
    };

    return props.informations.map((i, index) => {
      return (
        <div key={"" + index}>
          <ListItem sx={{
            minHeight: 100,
          }}>
            {createItemIcon(i.type)}
            <ListItemText primary={i.title} secondary={
              <>
                <Typography variant="subtitle1" component="span">登録者：</Typography>
                <Typography variant="body1" component="span">{i.userName}</Typography><br />
                <Typography variant="subtitle1" component="span">掲載期間：</Typography>
                <Typography variant="body1" component="span">{i.startDate}{createPeriod(i.endDate)}</Typography><br /><br />
                {createText(i.text)}
              </>
            } />
            {createAction(i)}
          </ListItem>
          <Divider />
        </div>
      );
    });
  };

  const createAddAction = () => {
    return (props.role === 'Manager' || props.role === 'Approver') ? (
      <Tooltip title="新しいお知らせを登録する">
        <IconButton sx={{
          color: (theme) => theme.palette.secondary.main,
          margin: 1,
        }} edge="end" aria-label="edit" onClick={() => addInformation()}>
          <AddComment />
        </IconButton>
      </Tooltip>
    ) : '';
  };

  return (
    <>
      <Typography sx={{ margin: 2 }} variant="h5" gutterBottom>
        <Comment fontSize='large' color='primary' sx={{ marginBottom: 1, marginRight: 1, verticalAlign: 'middle' }} />
        お知らせを確認する{createAddAction()}
      </Typography>
      <Paper sx={{
        minWidth: 275,
        margin: 2,
        padding: 1,
        backgroundColor: (theme) => theme.palette.primary[50],
      }} elevation={3}>
        <List component="nav" aria-label="My editing acts">
          {createList()}
        </List>
      </Paper>
      <Dialog
        open={openSubDialog}
        keepMounted
        onClose={closeSubDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">お知らせの削除</DialogTitle>
        <DialogContent>
          <Typography>お知らせを削除してもよろしいですか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteInformationAction} color="primary">
            OK
          </Button>
          <Button onClick={closeSubDialog} color="primary">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
      <InformationAddDialog
        open={openAddInformation}
        modeEditInformation={modeEditInformation}
        role={props.role}
        additionalItem={selectedInformation}
        accounts={props.accounts}
        updateItem={updateItem}
        handleClose={handleAddInformationClose}
        handleOk={handleOkAddInformation}
      />
    </>
  );

};