import actionCreatorFactory from 'typescript-fsa';
import { Dispatch, Action } from 'redux';
import { API, Storage } from 'aws-amplify';

import { appActions } from './appAction';
import { UsersResult, UserGroup, User, State } from '../states/settingState';
import { AdditionUser } from '../components/Setting';

const actionCreator = actionCreatorFactory();

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

const downloadForm = async (path: string) => {
  const file = await Storage.get(path, { level: 'private' });
  const xhr = new XMLHttpRequest();
  xhr.open('GET', '' + file);
  xhr.responseType = 'blob';
  xhr.onloadend = function () {
    if (xhr.status !== 200) {
      return false;
    }
    //const blob = new Blob([xhr.response], { "type" : "text/plain" });
    //const blob = new Blob([xhr.response], { "type" : "application/msword" });
    //const blob = new Blob([xhr.response], { "type" : "image/jpeg" });
    const blob = new Blob([xhr.response]);
    const link = document.createElement('a');
    link.download = path;
    link.href = window.URL.createObjectURL(blob);
    link.click();
    //window.navigator.msSaveOrOpenBlob(xhr.response, fileName);
  };
  xhr.send();
};

export const settingAction = {
  getUser: actionCreator<UsersResult>('ACTIONS_GET_USER'),
  getUserGroups: actionCreator<UserGroup[]>('ACTIONS_GET_USERGROUPS'),
  setCompleteUsersAddtion: actionCreator<boolean>('ACTIONS_SET_COMPLETE_USERS_ADDTION'),
  getUserAsync: (accountId: string, idid: string, start: number, size: number) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {}
        }
        const res = await API.get(API_NAME, `/users?accountid=${accountId}&start=${start}&size=${size}`, myInit);
        const res2 = await API.get(API_NAME, `/usergroups?accountid=${accountId}&idid=${idid}`, myInit);

        dispatch(settingAction.getUser(res));
        dispatch(settingAction.getUserGroups(res2.userGroups));

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  putUserAsync: (accountId: string, start: number, size: number, item: User, id: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {},
          body: {
            accountId: accountId,
            id: id,
            name: item.name,
            role: item.role,
            userGroupId: item.userGroupId,
            enabled: item.enabled,
          },
        };
        const url = `/users/${item.userId}`;
        await API.put(API_NAME, url, myInit);

        // 再取得
        const myInit2 = {
          headers: {}
        }
        const res = await API.get(API_NAME, `/users?accountid=${accountId}&start=${start}&size=${size}`, myInit2);
        dispatch(settingAction.getUser(res));

      } catch (err) {
        console.log(err);
      };
      dispatch(appActions.setLoading(false));
    };
  },
  postUsersAsync: (accountId: string, idid: string, start: number, size: number, id: string, postUsers: AdditionUser[]) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {},
          body: {
            accountId: accountId,
            idid: idid,
            id: id,
            postUsers: postUsers,
          },
        };
        const url = `/users`;
        const result = await API.post(API_NAME, url, myInit);

        // 再取得
        const myInit2 = {
          headers: {}
        }
        const res = await API.get(API_NAME, `/users?accountid=${accountId}&start=${start}&size=${size}`, myInit2);
        dispatch(settingAction.getUser(res));

        const newOutPath = result.outPath.replace(/private\/.*?\//, '');
        downloadForm(newOutPath);
        dispatch(settingAction.setCompleteUsersAddtion(true));

      } catch (err) {
        console.log(err);
      };
      dispatch(appActions.setLoading(false));
    };
  },
  setCompleteActTypeAddtion: actionCreator<boolean>('ACTIONS_SET_COMPLETE_ACTTYPE_ADDTION'),
  postActTypeAsync: (accountId: string, idid: string, id: string, fileName: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {},
          body: {
            accountId: accountId,
            idid: idid,
            id: id,
            fileName: fileName,
          },
        };
        const url = `/acttype`;
        const result = await API.post(API_NAME, url, myInit);

        const newOutPath = result.outPath.replace(/private\/.*?\//, '');
        downloadForm(newOutPath);
        dispatch(settingAction.setCompleteActTypeAddtion(true));

      } catch (err) {
        console.log(err);
      };
      dispatch(appActions.setLoading(false));
    };
  },
  postUserGroupsAsync: (accountId: string, idid: string, id: string, fileName: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {},
          body: {
            accountId: accountId,
            idid: idid,
            id: id,
            fileName: fileName,
          },
        };
        const url = `/usergroups`;
        await API.post(API_NAME, url, myInit);

        dispatch(settingAction.getUserGroupsState({ state: 'Processing' }));

      } catch (err) {
        console.log(err);
      };
      dispatch(appActions.setLoading(false));
    };
  },
  getUserGroupsState: actionCreator<State>('ACTIONS_GET_USERGROUPS_STATE'),
  getUserGroupsStateAsync: (accountId: string, idid: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {}
        }
        const res = await API.get(API_NAME, `/usergroups/state?accountid=${accountId}&idid=${idid}`, myInit);

        dispatch(settingAction.getUserGroupsState(res));

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  postCatalogAsync: (accountId: string, idid: string, id: string, fileName: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {},
          body: {
            accountId: accountId,
            idid: idid,
            id: id,
            fileName: fileName,
          },
        };
        const url = `/catalog`;
        await API.post(API_NAME, url, myInit);

        dispatch(settingAction.getCatalogState({ state: 'Processing' }));

      } catch (err) {
        console.log(err);
      };
      dispatch(appActions.setLoading(false));
    };
  },
  getCatalogState: actionCreator<State>('ACTIONS_GET_CATALOG_STATE'),
  getCatalogStateAsync: (accountId: string, idid: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {}
        }
        const res = await API.get(API_NAME, `/catalog/state?accountid=${accountId}&idid=${idid}`, myInit);

        dispatch(settingAction.getCatalogState(res));

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  postFieldAsync: (accountId: string, idid: string, id: string, fileName: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {},
          body: {
            accountId: accountId,
            idid: idid,
            id: id,
            fileName: fileName,
          },
        };
        const url = `/field`;
        await API.post(API_NAME, url, myInit);

        dispatch(settingAction.getFieldState({ state: 'Processing' }));

      } catch (err) {
        console.log(err);
      };
      dispatch(appActions.setLoading(false));
    };
  },
  getFieldState: actionCreator<State>('ACTIONS_GET_FIELD_STATE'),
  getFieldStateAsync: (accountId: string, idid: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {}
        }
        const res = await API.get(API_NAME, `/field/state?accountid=${accountId}&idid=${idid}`, myInit);

        dispatch(settingAction.getFieldState(res));

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  deleteUserAsync: (accountId: string, start: number, size: number, id: string, userId: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const url = `/users/${userId}`;
        const myInit = {
          headers: {},
          body: {
            id: id,
          },
        }
        const result = await API.del(API_NAME, url, myInit);
        console.log(result);

        // 再取得
        const myInit2 = {
          headers: {}
        }
        const res = await API.get(API_NAME, `/users?accountid=${accountId}&start=${start}&size=${size}`, myInit2);
        dispatch(settingAction.getUser(res));

      } catch (err) {
        console.log(err);
      };
      dispatch(appActions.setLoading(false));
    };
  },
};
