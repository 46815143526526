import { useTheme, View, Text, Heading, Image } from '@aws-amplify/ui-react'
// import Link from '@mui/material/Link';


const AuthenticatorComponent = {
    Header() {
        const { tokens } = useTheme()
        return (<View padding={tokens.space.xxxl}>
            <Image
                alt="LCRUD logo"
                src="/Legal_CRUD_logo.png"
            />
        </View>
        )
    },
    Footer() {
        const { tokens } = useTheme()
        return (
            <View textAlign="center" padding={tokens.space.xxxl}>
                {/* <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        window.open('https://test.co.jp/', `test`);
                    }}
                >
                    Test Link
                </Link> */}
                <Text color={`${tokens.colors.neutral['80']}`}>
                    {/* &copy; All Rights Reserved */}
                </Text>
            </View>
        )
    },
    SignIn: {
        Header() {
            return <Heading>サインイン</Heading>
        },
    },
    ConfirmSignIn: {
        Header() {
            return <Heading>サインイン</Heading>
        },
    },
    ResetPassword: {
        Header() {
            return <Heading>パスワードを忘れた場合</Heading>
        },
    },
    ConfirmResetPassword: {
        Header() {
            return <Heading>パスワードのリセット</Heading>
        },
    },
}

export default AuthenticatorComponent