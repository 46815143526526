import React from 'react';

import { useNavigate } from "react-router-dom";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

import Dashboard from '@mui/icons-material/Dashboard';
import Comment from '@mui/icons-material/Comment';
import Search from '@mui/icons-material/Search';
import Newspaper from '@mui/icons-material/Newspaper';
import Edit from '@mui/icons-material/Edit';
import Approval from '@mui/icons-material/Approval';
import BackupTable from '@mui/icons-material/BackupTable';
import NoteAdd from '@mui/icons-material/NoteAdd';
import FileDownload from '@mui/icons-material/FileDownload';
// import SettingsIcon from '@mui/icons-material/Settings';

import { Conditions } from '../states/searchState';

type Props = {
  accountid: string;
  role: string;
  id: string;
  idid: string;
  userGroupId: string;
  getApprovalActs: (accountid: string, id: string) => void;
  setLoading: (loading: boolean) => void;
  getEditing: (accountid: string, id: string) => void;
  getWhatsnew: (accountid: string, id: string) => void;
  searchRepealAsync: (c: Conditions, accountid: string) => void;
  getInformations: (accountId: string, id: string) => void;
  setOpenNewActDialog: (openNewActDialog: boolean) => void;
  setOpenPubContentsDialog: (openPubContentsDialog: boolean) => void;
  getPubContentsItemAsync: (accountid: string) => void;
  getUserAsync: (accountId: string, idid: string, start: number, size: number) => void;
};

export const MainMenu: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const current = () => {
  //     handleClose();
  //     history.push('/current');
  // };

  const main = () => {
    handleClose();
    navigate('/');
  };

  const search = () => {
    handleClose();
    navigate('/search');
  };

  const editing = () => {
    props.setLoading(true);
    props.getEditing(props.accountid, props.id);
    handleClose();
    navigate('/editing');
  };

  const whatsnew = () => {
    props.setLoading(true);
    props.getWhatsnew(props.accountid, props.id);
    handleClose();
    navigate('/whatsnew');
  };

  const approval = () => {
    props.setLoading(true);
    props.getApprovalActs(props.accountid, props.id);
    handleClose();
    navigate('/approval');
  };

  const repeal = () => {
    props.setLoading(true);
    props.searchRepealAsync({ mode: 'act', words: '', rowsPerPage: 10, page: 0, userGroupId: props.userGroupId, }, props.accountid);
    handleClose();
    navigate('/repeal');
  };

  const information = () => {
    props.setLoading(true);
    props.getInformations(props.accountid, props.id);
    handleClose();
    navigate('/informations');
  };

  const newAct = () => {
    props.setOpenNewActDialog(true);
    handleClose();
  };

  const pubContents = () => {
    props.setLoading(true);
    props.getPubContentsItemAsync(props.accountid);
    props.setOpenPubContentsDialog(true);
    handleClose();
  };

  // const settings = () => {
  //   props.setLoading(true);
  //   props.getUserAsync(props.accountid, props.idid, 0, 5);
  //   handleClose();
  //   navigate('/settings');
  // };

  // 特権用メニュー退避
  // const tagsManagement = () => {
  //     props.setLoading(true);
  //     handleClose();
  //     history.push('/tags');
  //     props.setLoading(false);
  // };

  // (<MenuItem key={"6"} onClick={current}>Current（Test用）</MenuItem>),
  // 特権用メニュー退避(<MenuItem key={"8"} onClick={tagsManagement}>体系・分野管理</MenuItem>),
  const createMenu = () => {
    const menus = props.role === 'Viewer' ? [
      (<MenuItem key={"1"} onClick={main}>
        <ListItemIcon>
          <Dashboard fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">ダッシュボード</Typography>
      </MenuItem>),
      (<MenuItem key={"9"} onClick={information}>
        <ListItemIcon>
          <Comment fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">お知らせを確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"2"} onClick={search}>
        <ListItemIcon>
          <Search fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">文書を検索・編集する</Typography>
      </MenuItem>),
      (<MenuItem key={"3"} onClick={whatsnew}>
        <ListItemIcon>
          <Newspaper fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">最近更新された文書を確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"8"} onClick={repeal}>
        <ListItemIcon>
          <BackupTable fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">廃止・失効された文書を確認する</Typography>
      </MenuItem>),
    ] : props.role === 'Editor' ? [
      (<MenuItem key={"1"} onClick={main}>
        <ListItemIcon>
          <Dashboard fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">ダッシュボード</Typography>
      </MenuItem>),
      (<MenuItem key={"9"} onClick={information}>
        <ListItemIcon>
          <Comment fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">お知らせを確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"2"} onClick={search}>
        <ListItemIcon>
          <Search fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">文書を検索・編集する</Typography>
      </MenuItem>),
      (<MenuItem key={"3"} onClick={whatsnew}>
        <ListItemIcon>
          <Newspaper fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">最近更新された文書を確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"4"} onClick={editing}>
        <ListItemIcon>
          <Edit fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">編集を再開する</Typography>
      </MenuItem>),
      (<MenuItem key={"8"} onClick={repeal}>
        <ListItemIcon>
          <BackupTable fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">廃止・失効された文書を確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"7"} onClick={newAct}>
        <ListItemIcon>
          <NoteAdd fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">新規作成する</Typography>
      </MenuItem>),
    ] : props.role === 'Approver' ? [
      (<MenuItem key={"1"} onClick={main}>
        <ListItemIcon>
          <Dashboard fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">ダッシュボード</Typography>
      </MenuItem>),
      (<MenuItem key={"9"} onClick={information}>
        <ListItemIcon>
          <Comment fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">お知らせを確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"2"} onClick={search}>
        <ListItemIcon>
          <Search fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">文書を検索・編集する</Typography>
      </MenuItem>),
      (<MenuItem key={"3"} onClick={whatsnew}>
        <ListItemIcon>
          <Newspaper fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">最近更新された文書を確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"4"} onClick={editing}>
        <ListItemIcon>
          <Edit fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">編集を再開する</Typography>
      </MenuItem>),
      (<MenuItem key={"5"} onClick={approval}>
        <ListItemIcon>
          <Approval fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">編集内容を承認する</Typography>
      </MenuItem>),
      (<MenuItem key={"8"} onClick={repeal}>
        <ListItemIcon>
          <BackupTable fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">廃止・失効された文書を確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"7"} onClick={newAct}>
        <ListItemIcon>
          <NoteAdd fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">新規作成する</Typography>
      </MenuItem>),
      (<MenuItem key={"10"} onClick={pubContents}>
        <ListItemIcon>
          <FileDownload fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">HTML版をダウンロードする</Typography>
        </MenuItem>),
    ] : [
      (<MenuItem key={"1"} onClick={main}>
        <ListItemIcon>
          <Dashboard fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">ダッシュボード</Typography>
      </MenuItem>),
      (<MenuItem key={"9"} onClick={information}>
        <ListItemIcon>
          <Comment fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">お知らせを確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"2"} onClick={search}>
        <ListItemIcon>
          <Search fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">文書を検索・編集する</Typography>
      </MenuItem>),
      (<MenuItem key={"3"} onClick={whatsnew}>
        <ListItemIcon>
          <Newspaper fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">最近更新された文書を確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"4"} onClick={editing}>
        <ListItemIcon>
          <Edit fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">編集を再開する</Typography>
      </MenuItem>),
      (<MenuItem key={"5"} onClick={approval}>
        <ListItemIcon>
          <Approval fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">編集内容を承認する</Typography>
      </MenuItem>),
      (<MenuItem key={"8"} onClick={repeal}>
        <ListItemIcon>
          <BackupTable fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">廃止・失効された文書を確認する</Typography>
      </MenuItem>),
      (<MenuItem key={"7"} onClick={newAct}>
        <ListItemIcon>
          <NoteAdd fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">新規作成する</Typography>
      </MenuItem>),
      (<MenuItem key={"10"} onClick={pubContents}>
        <ListItemIcon>
          <FileDownload fontSize="small" color='primary' />
        </ListItemIcon>
        <Typography variant="inherit">HTML版をダウンロードする</Typography>
        </MenuItem>),
      // (<MenuItem key={"11"} onClick={settings}>
      //   <ListItemIcon>
      //     <SettingsIcon fontSize="small" color='primary' />
      //   </ListItemIcon>
      //   <Typography variant="inherit">環境設定</Typography>
      //   </MenuItem>),
    ];
    return menus;
  };

  return (
    <div>
      <IconButton
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          marginLeft: -1,
          marginRight: 2,
        }}
        color="inherit"
        aria-label="Open drawer">
        <MenuIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {createMenu()}
      </Menu>
    </div>
  );
}
